import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`14th May 2021`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now create a `}<a parentName="p" {...{
            "href": "type://HealthChecker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthChecker.html"
          }}>{`type://HealthChecker`}</a>{` that updates its healthiness periodically. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3392"
          }}>{`#3392`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3406"
          }}>{`#3406`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HealthChecker.of(() -> {
    HealthCheckStatus status = ...;
    UnmodifiableFuture.completedFuture(status);
}, Duration.ofSeconds(5));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create a propagating `}<a parentName="p" {...{
            "href": "type://ContextAwareExecutorService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareExecutorService.html"
          }}>{`type://ContextAwareExecutorService`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://ContextAwareScheduledExecutorService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareScheduledExecutorService.html"
          }}>{`type://ContextAwareScheduledExecutorService`}</a>{` using `}<a parentName="p" {...{
            "href": "type://RequestContext#makeContextPropagating(ExecutorService):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#makeContextPropagating(java.util.concurrent.ExecutorService)?full"
          }}>{`type://RequestContext#makeContextPropagating(ExecutorService)?full`}</a>{`
and `}<a parentName="p" {...{
            "href": "type://RequestContext#makeContextPropagating(ScheduledExecutorService):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#makeContextPropagating(java.util.concurrent.ScheduledExecutorService)?full"
          }}>{`type://RequestContext#makeContextPropagating(ScheduledExecutorService)?full`}</a>{`.
The executors propagate a `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` to submitted tasks when a submission thread is
a request-context-aware thread. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3483"
          }}>{`#3483`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ExecutorService myExecutor = Executors.newSingleThreadExecutor();
ExecutorService contextAwareExecutor = RequestContext.makeContextPropagating(myExecutor);
// Make sure the current thread is a request-context-aware thread
RequestContext ctx = RequestContext.current();
contextAwareExecutor.execute(() -> {
    assert RequestContext.current() == ctx;
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set a maximum allowed length of a server response in the request level
using `}<a parentName="p" {...{
            "href": "type://WebClientRequestPreparation#maxResponseLength(long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientRequestPreparation.html#maxResponseLength(long)"
          }}>{`type://WebClientRequestPreparation#maxResponseLength(long)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3565"
          }}>{`#3565`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient client = ...;
client.prepare()
      .maxResponseLength(maxResponseLength)
      ...
      .execute();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now add or set HTTP headers and query params with `}<inlineCode parentName="p">{`java.util.Map`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3499"
          }}>{`#3499`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3502"
          }}>{`#3502`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{` HttpHeaders
    .builder()
    .add(Map.of("foo", "1"))
    .set(Map.of("bar", "2"));

QueryParams.builder()
           .add(Map.of("page", "1"))
           .set(Map.of("size", "2"));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now disable DNS query metrics using `}<a parentName="p" {...{
            "href": "type://DnsResolverGroupBuilder#disableDnsQueryMetrics()"
          }}>{`type://DnsResolverGroupBuilder#disableDnsQueryMetrics()`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3368"
          }}>{`#3368`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3552"
          }}>{`#3552`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory.builder()
             .domainNameResolverCustomizer(customizer -> {
                 customizer.disableDnsQueryMetrics();
             })
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create a `}<a parentName="p" {...{
            "href": "https://jsonlines.org/"
          }}>{`JSON Lines`}</a>{` response using `}<a parentName="p" {...{
            "href": "type://JsonLines:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/streaming/JsonLines.html"
          }}>{`type://JsonLines`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3446"
          }}>{`#3446`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3456"
          }}>{`#3456`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Publisher<MyObject> publisher = StreamMessage.of(new MyObject(...), new MyObject(...), ...);
HttpReponse response = JsonLines.fromPublisher(publisher);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create an `}<a parentName="p" {...{
            "href": "type://RpcRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RpcRequest.html"
          }}>{`type://RpcRequest`}</a>{` with a service name using
`}<a parentName="p" {...{
            "href": "type://RpcRequest#of(Class,String,String,Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RpcRequest.html#of(java.lang.Class,java.lang.String,java.lang.String,java.lang.Iterable)"
          }}>{`type://RpcRequest#of(Class,String,String,Iterable)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3537"
          }}>{`#3537`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize `}<a parentName="p" {...{
            "href": "type://MetricCollectingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/MetricCollectingService.html"
          }}>{`type://MetricCollectingService`}</a>{` with `}<a parentName="p" {...{
            "href": "type://MetricCollectingServiceConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/MetricCollectingServiceConfigurator.html"
          }}>{`type://MetricCollectingServiceConfigurator`}</a>{`
when using Spring integration. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3536"
          }}>{`#3536`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public MetricCollectingServiceConfigurator metricCollectingServiceConfigurator() {
    return builder -> builder
            .successFunction((context, log) -> {
                final int statusCode = log.responseHeaders().status().code();
                return (statusCode >= 200 && statusCode < 400) || statusCode == 404;
            });
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Various performance optimizations. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3534"
        }}>{`#3534`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3535"
        }}>{`#3535`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3537"
        }}>{`#3537`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3538"
        }}>{`#3538`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3539"
        }}>{`#3539`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3543"
        }}>{`#3543`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3548"
        }}>{`#3548`}</a></li>
      <li parentName="ul">{`You can now null-safely access the elements of collections and arrays in
`}<a parentName="li" {...{
          "href": "https://kotlinlang.org/docs/whatsnew15.html#improvements-to-handling-nullability-annotations"
        }}>{`Kotlin 1.5`}</a>{`.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3527"
        }}>{`#3527`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3558"
        }}>{`#3558`}</a></li>
      <li parentName="ul">{`You can now use code completion for `}<inlineCode parentName="li">{`armeria.ssl`}</inlineCode>{` when using IntelliJ IDEA. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3507"
        }}>{`#3507`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria server now correctly sends `}<inlineCode parentName="li">{`Connection: close`}</inlineCode>{` header on an invalid HTTP/1 request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3506"
        }}>{`#3506`}</a></li>
      <li parentName="ul">{`You no longer see an infinity loop or an `}<inlineCode parentName="li">{`IllegalStateException: Recurse update`}</inlineCode>{` when invalidating
a DNS cache. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3528"
        }}>{`#3528`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
        }}>{`type://LoggingService`}</a>{` now correctly logs a request failed with the server error class(5xx) that is served
by `}<a parentName="li" {...{
          "href": "type://TransientService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientService.html"
        }}>{`type://TransientService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3551"
        }}>{`#3551`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3559"
        }}>{`#3559`}</a></li>
      <li parentName="ul">{`You no longer see trailing dollar signs from a service name. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3508"
        }}>{`#3508`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when a request times out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3564"
        }}>{`#3564`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Caffeine 2.9.0 -> 2.9.1`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.20 -> 4.1.21`}</li>
      <li parentName="ul">{`java-jwt 3.15.0 -> 3.16.0`}</li>
      <li parentName="ul">{`Micrometer 1.6.6 -> 1.7.0`}
        <ul parentName="li">
          <li parentName="ul">{`Note that `}<inlineCode parentName="li">{`PrometheusMeterRegistry`}</inlineCode>{` will work with the Prometheus `}<inlineCode parentName="li">{`simpleclient`}</inlineCode>{` dependency version 0.10.0
or later only. See `}<a parentName="li" {...{
              "href": "https://github.com/micrometer-metrics/micrometer/releases/tag/v1.7.0"
            }}>{`Micrometer 1.7.0 release note`}</a>{` for more information.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reactor 3.4.5 -> 3.4.6`}</li>
      <li parentName="ul">{`scala-collection-compat 2.4.3 -> 2.4.4`}</li>
      <li parentName="ul">{`scala-java8-compat 0.9.1 -> 1.0.0`}</li>
      <li parentName="ul">{`Spring 5.3.6 -> 5.3.7`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['amitvc', 'anuraaga', 'hexoul', 'hirakida', 'ikhoon', 'jrhee17', 'KarboniteKream', 'kojilin', 'm50d', 'max904-github', 'minwoox', 'nirvanarsc', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      